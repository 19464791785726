import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';
import Navigation from './navigation/Navigation'
import HomeComponent from './pages/HomeComponent';
import ReelComponent from './pages/ReelComponent';
import WorksComponent from './pages/WorksComponent';
import AboutComponent from './pages/AboutComponent';
import ProjectComponent from './pages/ProjectComponent';
import HeaderComponent from './HeaderComponent';
//import CustomScrolling from './service/custom_smooth_scrolling'
import LocomotiveScroll from 'locomotive-scroll';

import { Content } from './App.style'
import { Nav } from './App.style'

import { StyleSheetManager } from 'styled-components'


// Stats
// const stats = new Stats(); stats.showPanel(0); document.body.appendChild(stats.dom);

const consoleStyle = ""

const cursor = document.querySelector('.cursor');
const trigger = document.querySelector('button');

let mouseX = 0;
let mouseY = 0;
let mouseA = 0;

let innerX = 0;
let innerY = 0;

let outerX = 0;
let outerY = 0;

let loop = null;

document.addEventListener('mousemove', (e) => {
  mouseX = e.clientX;
  mouseY = e.clientY;

  if (!loop) {
    loop = window.requestAnimationFrame( moveCursor );
  }
});

//trigger.addEventListener('mouseenter', () => {
//  cursor.classList.add('cursor--hover');
//});

//trigger.addEventListener('mouseleave', () => {
//  cursor.classList.remove('cursor--hover');
//});

//console.log('cursorOuter', cursorOuter);

function moveCursor() {
  //const cursorInner = ;
  //const cursorOuter =;
  // stats.begin();

  loop = null;

  innerX = lerp(innerX, mouseX, 0.15);
  innerY = lerp(innerY, mouseY, 0.15);

  outerX = lerp(outerX, mouseX, 0.13);
  outerY = lerp(outerY, mouseY, 0.13);

  const angle = Math.atan2(mouseY - outerY, mouseX - outerX) * 180 / Math.PI;

  const normalX = Math.min(Math.floor((Math.abs(mouseX - outerX) / outerX) * 1000) / 1000, 1);
  const normalY = Math.min(Math.floor((Math.abs(mouseY - outerY) / outerY) * 1000) / 1000, 1);
  const normal  = normalX + normalY * .5;
  const skwish  = normal * .7;

  //console.log('cursorInner', cursorInner);

  document.querySelector('.cursor-move-inner').style.transform = `translate3d(${innerX}px, ${innerY}px, 0)`;
  document.querySelector('.cursor-move-outer').style.transform = `translate3d(${outerX}px, ${outerY}px, 0) rotate(${angle}deg) scale(${1 + skwish}, ${1 - skwish})`;

  // stats.end();

  // Stop loop if interpolation is done.
  if (normal !== 0) {
    loop = window.requestAnimationFrame(moveCursor);
  }
}

function lerp(s, e, t) {
  return (1 - t) * s + t * e;
}




// Virtual Scroll Setup

const SetScroll = () => {

/*
  const section = document.querySelector('.content')
  const smooth = new Smooth({
    native: true,
    section: section,
    ease: 0.1,
  })
  smooth.init()
*/


  const section = document.querySelector('.content')
/*
  const opacity = document.querySelector('.content')

  const smooth = new CustomScrolling({
    native: true,
    section: section,
    opacity: opacity,
  })

  smooth.init()
*/
  const scroll = new LocomotiveScroll({
    el: section,
    smooth: true,
    smoothMobile: true,
    touchMultiplier: 3.5,
    useKeyboard: true
  });

}




class App extends Component {


  componentDidMount() {

    SetScroll()
  }


  render() {
    return (

      <Router>

      <div className="cursors">
        <span className="cursor">
          <span className="cursor-move-inner">
            <span className="cursor-inner"></span>
            <span className="cursor-text"></span>
          </span>
          <span className="cursor-move-outer">
            <span className="cursor-outer"></span>
          </span>
        </span>
      </div>

      <Content className="content">
        <div>
          <Route exact path='/' component={HomeComponent}></Route>
          <Route exact path='/reel' component={ReelComponent}></Route>
          <Route exact path='/works' component={WorksComponent}></Route>
          <Route exact path='/about' component={AboutComponent}></Route>
          <Route exact path='/project/:id' component={ProjectComponent}></Route>
        </div>
      </Content>

      <Nav className="navigation">
        <Navigation></Navigation>
      </Nav>

      </Router>
    )
  }
}

export default App;
