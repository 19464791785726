import React, {useEffect, useRef} from 'react'
import { Link } from 'react-router-dom'
import { ViewButton } from './ViewNavigation.style'
import { ViewNavigationWrapper } from './ViewNavigation.style'
import { NavigationWrapper } from './ViewNavigation.style'
import { MainMenuButton } from './ViewNavigation.style'
import { SoundButton } from './ViewNavigation.style'
import { MainMenuWrapper } from './ViewNavigation.style'
import { TitleLarge2 } from './ViewNavigation.style'
import { TitleLarge3 } from './ViewNavigation.style'
import { TitleLarge4 } from './ViewNavigation.style'
import { TitleLarge5 } from './ViewNavigation.style'
import { TitleLarge6 } from './ViewNavigation.style'
import { MenuItem } from './ViewNavigation.style'
import { MenuLine } from './ViewNavigation.style'
import { MenuSide } from './ViewNavigation.style'
import { TweenMax, TimelineMax, CSSPlugin, Expo } from 'gsap/all'

import { StyleSheetManager } from 'styled-components'

let menuToggle = true



const ViewNavigation = ({fn}) => {

  const openMenu = () => {
    if(menuToggle) {
      menuToggle = false
      TweenMax.to(document.querySelector(String("."+MainMenuWrapper.styledComponentId)),2,{x:"-400px", ease:Expo.easeOut, delay:.3})
    }
    else {
      menuToggle = true
      TweenMax.to(document.querySelector(String("."+MainMenuWrapper.styledComponentId)),2,{x:"400px", ease:Expo.easeOut, delay:.3})
    }
  }

  const mouseOver = (ref) => {
    //console.log(ViewButton.target);
    //ref.stopPropagation()
    TweenMax.to(ref.currentTarget,2,{opacity:4, scale:1.5, ease:Expo.easeOut})
    TweenMax.to(document.querySelector(String("."+ViewNavigationWrapper.styledComponentId)),2,{opacity:.6, ease:Expo.easeOut})
    //console.log('over');
    //TweenMax.to(document.querySelector('.cursor-move-inner'),2,{scale:"3", ease:Expo.easeOut})
    //document.querySelector('.cursor-move-inner').style.transform = `translate3d(${ref.currentTarget.x}px, ${ref.currentTarget.y}px, 0)`;
    //document.querySelector('.cursor-move-outer').style.transform = `translate3d(scale(${1 + 200}, ${1 - 200})`;
  }

  const mouseOut = (ref) => {
    //console.log(ref.currentTarget);
    //ref.stopPropagation()
    TweenMax.to(ref.currentTarget,2,{opacity:.6, scale:1, ease:Expo.easeOut})
    TweenMax.to(document.querySelector(String("."+ViewNavigationWrapper.styledComponentId)),2,{opacity:1, ease:Expo.easeOut})
    //console.log('over');
    //TweenMax.to(document.querySelector('.cursor-move-inner'),2,{scale:"3", ease:Expo.easeOut})
    //document.querySelector('.cursor-move-inner').style.transform = `translate3d(${ref.currentTarget.x}px, ${ref.currentTarget.y}px, 0)`;
    //document.querySelector('.cursor-move-outer').style.transform = `translate3d(scale(${1 + 200}, ${1 - 200})`;
  }


  useEffect(() => {


    document.querySelector(String("."+ViewNavigationWrapper.styledComponentId)).childNodes.forEach(
      (c,i) =>

        TweenMax.to(
          c,
          1.4,
        {
          x:"0px",
          ease:Expo.easeOut,
          delay:3+(i/7)
        })

      )
  }, [])


  return (
      <NavigationWrapper>

          <ViewNavigationWrapper>
            <ViewButton bg={require('../../../svg/nav_01.svg')} onClick={fn.bind(this, '0')} onMouseOver={mouseOver} onMouseOut={mouseOut}/>
            <ViewButton bg={require('../../../svg/nav_02.svg')} onClick={fn.bind(this, '5')} onMouseOver={mouseOver} onMouseOut={mouseOut}/>
            <ViewButton bg={require('../../../svg/nav_03.svg')} onClick={fn.bind(this, '2')} onMouseOver={mouseOver} onMouseOut={mouseOut}/>
            <ViewButton bg={require('../../../svg/nav_04.svg')} onClick={fn.bind(this, '1')} onMouseOver={mouseOver} onMouseOut={mouseOut}/>
            <ViewButton bg={require('../../../svg/nav_05.svg')} onClick={fn.bind(this, '4')} onMouseOver={mouseOver} onMouseOut={mouseOut}/>
            <ViewButton bg={require('../../../svg/nav_06.svg')} onClick={fn.bind(this, '3')} onMouseOver={mouseOver} onMouseOut={mouseOut}/>
          </ViewNavigationWrapper>


      </NavigationWrapper>
)}

export default ViewNavigation;
