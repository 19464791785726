import React, {useEffect, useRef} from 'react'
import { NavLink } from 'react-router-dom'
import { ViewButton } from './Navigation.style'
import { ViewNavigationWrapper } from './Navigation.style'
import { NavigationWrapper } from './Navigation.style'
import { MainMenuButton } from './Navigation.style'
import { SoundButton } from './Navigation.style'
import { MainMenuWrapper } from './Navigation.style'
import { TitleLarge2 } from './Navigation.style'
import { TitleLarge3 } from './Navigation.style'
import { TitleLarge4 } from './Navigation.style'
import { TitleLarge5 } from './Navigation.style'
import { TitleLarge6 } from './Navigation.style'
import { MenuItem } from './Navigation.style'
import { MenuLine } from './Navigation.style'
import { MenuSide } from './Navigation.style'
import { TweenMax, TimelineMax, CSSPlugin, Expo } from 'gsap/all'


let menuToggle = true

const Navigation = ({fn}) => {

  const openMenu = () => {
    if(menuToggle) {
      menuToggle = false
      TweenMax.to(document.querySelector(String("."+MainMenuWrapper.styledComponentId)),2,{x:"-400px", ease:Expo.easeOut, delay:.3})
    }
    else {
      menuToggle = true
      TweenMax.to(document.querySelector(String("."+MainMenuWrapper.styledComponentId)),2,{x:"400px", ease:Expo.easeOut, delay:.3})
    }
  }

  // classList.toggle

  useEffect(() => {

    TweenMax.to(document.querySelector(String("."+MainMenuButton.styledComponentId)),1.4,{x:"0px", ease:Expo.easeOut, delay:.9})
    TweenMax.to(document.querySelector(String("."+SoundButton.styledComponentId)),1.4,{x:"0px", ease:Expo.easeOut, delay:1.8})

  }, [])


  return (
    <NavigationWrapper>

        <SoundButton bg={require('../svg/sound.svg')} />

        <MainMenuWrapper>

          <MenuSide bg={require('../images/menu.jpg')}/>

          <TitleLarge2>The</TitleLarge2>
          <TitleLarge5>REASON</TitleLarge5>
          <TitleLarge3>Table</TitleLarge3>
              <br></br>
          <TitleLarge4>of</TitleLarge4>
          <TitleLarge3>Contents</TitleLarge3>

          <MenuLine></MenuLine>

          <NavLink to='/' activeClassName='active' onClick={openMenu}>
            <MenuItem>HOME</MenuItem>
          </NavLink>

          <MenuLine></MenuLine>

          <NavLink to='/reel' activeClassName='active' onClick={openMenu}>
            <MenuItem>REEL</MenuItem>
          </NavLink>

          <MenuLine></MenuLine>

          <NavLink to='/works' activeClassName='active' onClick={openMenu}>
            <MenuItem>WORKS</MenuItem>
          </NavLink>

          <MenuLine></MenuLine>

          <NavLink to='/about' activeClassName='active' onClick={openMenu}>
            <MenuItem>ABOUT</MenuItem>
          </NavLink>

        </MainMenuWrapper>

        <MainMenuButton bg={require('../svg/main_menu.svg')} onClick={openMenu}/>
    </NavigationWrapper>
)}

export default Navigation;

//<video autoplay='true' loop='true' muted='true'>
//<source type='video/mp4' src={require('../images/menu.mp4')}/>
//</video>
