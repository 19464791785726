import React, { Component } from 'react'
import Three from '../layouts/three/ThreeScene'


class HomeComponent extends Component {

  componentDidMount() {

    document.querySelector('.content').style.overflowY = "hidden"
    window.dispatchEvent(new Event('resize'));
  }

  render() {
    return (
      <Three/>
    )
  }
}

export default HomeComponent;
