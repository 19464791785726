import React, { Component } from 'react'
import * as THREE from 'three'
import { Clock } from "three";
import { NoiseEffect, TextureEffect, EffectComposer, EffectPass, RenderPass, BlendFunction } from "postprocessing"
import GLTFLoader from 'three-gltf-loader'
import OrbitControls from 'three-orbitcontrols'
import { TweenMax, TimelineMax, Expo } from 'gsap/all'

import ViewNavigation from './viewnavigation/ViewNavigation'
import Headline from './headline/Headline'
import Grain from './grain/Grain'

import carModel from './../../models/cracked.gltf'
import scratches from '../../images/scratches.jpg'
import grain from '../../images/grain.png'


const Items = []
const Positions = []
const S = 30

let CurrentView = 0
let CurrentSelection
let AnimatedCamera = true
let Camera

var mouse = new THREE.Vector2(), INTERSECTED;


// CHANGE VIEW
const ChangeView = id => {

  AnimatedCamera = true
  CurrentView = id
  console.log('Change 3D View', id)

  TweenMax.to(Camera.position, 2, {
    x: 0,
    y: 0,
    z: 15,
    delay: 10,
    ease:Expo.easeOut
  })

  for (let i=0; i < S; i++){

    TweenMax.to(Items[i].position, 2.5, {
      x: Positions[id][i].positionx,
      y: Positions[id][i].positiony,
      z: Positions[id][i].positionz,
      ease:Expo.easeInOut,
      delay: i/20})

    TweenMax.to(Items[i].rotation, 2.5, {
      x: Positions[id][i].rotationx,
      y: Positions[id][i].rotationy,
      z: Positions[id][i].rotationz,
      ease:Expo.easeInOut,
      delay: i/20})
  }
}

// SELECT ITEM
const SelectItem = id => {

  AnimatedCamera = false
  CurrentSelection = id
  console.log('Select Item', id)

  TweenMax.to(Camera.position, 1.2, {
    x: Positions[CurrentView][id].camerapositionx,
    y: Positions[CurrentView][id].camerapositiony,
    z: Positions[CurrentView][id].camerapositionz,
    ease:Expo.easeOut
  })


  for (let i=0; i < S; i++){

    if (i < id) {

      TweenMax.to(Items[i].position, 1.2, {
        x: Positions[CurrentView][i].positionx - Positions[CurrentView][i].offsetpositionx,
        y: Positions[CurrentView][i].positiony - Positions[CurrentView][i].offsetpositiony,
        z: Positions[CurrentView][i].positionz - Positions[CurrentView][i].offsetpositionz,
        ease:Expo.easeOut,
        delay: 0})

      TweenMax.to(Items[i].rotation, 1.2, {
        x: Positions[CurrentView][i].rotationx,
        y: Positions[CurrentView][i].rotationy,
        z: Positions[CurrentView][i].rotationz,
        ease:Expo.easeOut,
        delay: 0})
    }
    else if (i > id) {

      TweenMax.to(Items[i].position, 1.2, {
        x: Positions[CurrentView][i].positionx + Positions[CurrentView][i].offsetpositionx,
        y: Positions[CurrentView][i].positiony + Positions[CurrentView][i].offsetpositiony,
        z: Positions[CurrentView][i].positionz + Positions[CurrentView][i].offsetpositionz,
        ease:Expo.easeOut,
        delay: 0})

      TweenMax.to(Items[i].rotation, 1.2, {
        x: Positions[CurrentView][i].rotationx,
        y: Positions[CurrentView][i].rotationy,
        z: Positions[CurrentView][i].rotationz,
        ease:Expo.easeOut,
        delay: 0})
    }
    else {

      TweenMax.to(Items[i].position, 1.2, {
        x: Positions[CurrentView][i].selectedpositionx,
        y: Positions[CurrentView][i].selectedpositiony,
        z: Positions[CurrentView][i].selectedpositionz,
        ease:Expo.easeOut,
        delay: 0})

      TweenMax.to(Items[i].rotation, 1.2, {
        x: Positions[CurrentView][i].rotationx - Positions[CurrentView][i].offsetrotationx,
        y: Positions[CurrentView][i].rotationy - Positions[CurrentView][i].offsetrotationy,
        z: Positions[CurrentView][i].rotationz - Positions[CurrentView][i].offsetrotationz,
        ease:Expo.easeOut,
        delay: 0})
    }
  }
}



class ThreeScene extends Component {

   componentDidMount() {

      const width = this.mount.clientWidth
      const height = this.mount.clientHeight

      this.mouse = new THREE.Vector2()

      // ADD SCENE
      this.scene = new THREE.Scene()

      // ADD CAMERA
      Camera = this.camera = new THREE.PerspectiveCamera(
        75,
        width / height,
        0.1,
        10000
      )

      // CAMERA START POSITION
      this.camera.position.z = 0
      TweenMax.to(this.camera.position, 5, {z: 15, ease: Expo.easeOut, delay: 3.8})

      // ADD RAYCASTER
      this.raycaster = new THREE.Raycaster()

      // ADD RENDERER
      this.renderer = new THREE.WebGLRenderer({ antialias: false, alpha: false })
      this.renderer.setClearColor('#000000',0)
      this.renderer.setSize(width, height)
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      this.mount.appendChild(this.renderer.domElement)

      // POST PRODUCTION
      this.composer = new EffectComposer( this.renderer )

      // Noise Effect
      this.noiseEffect = new NoiseEffect({ premultiply: true, blendFunction: BlendFunction.ADD})
      this.noiseEffect.blendMode.opacity.value = 0.5

      // Textrure Effect Scratches
      this.texture = new THREE.TextureLoader().load( scratches )
      this.textureEffect = new TextureEffect({ blendFunction: BlendFunction.REFLECT, texture: this.texture })
      this.textureEffect.blendMode.opacity.value = 0.4

      // Textrure Effect Grain
      this.textureGrain = new THREE.TextureLoader().load( grain )
      this.textureEffectGrain = new TextureEffect({ blendFunction: BlendFunction.ADD, texture: this.textureGrain }) //
      this.textureEffectGrain.blendMode.opacity.value = 0
      TweenMax.to(this.textureEffectGrain.blendMode.opacity, 2, { value: 0.6, delay:1 })

      // Effect Passes
      this.renderPass = new RenderPass(this.scene, this.camera)
      this.effectPass = new EffectPass(this.camera, this.textureEffect, this.textureEffectGrain, this.noiseEffect)
      this.effectPass.renderToScreen = true

      // Add Passes to EffectComposser
      this.composer.addPass( this.renderPass )
      this.composer.addPass( this.effectPass )

      this.clock = new Clock()

      /*
      const filmPass = new FilmPass(
          0.35,   // noise intensity
          0.025,  // scanline intensity
          648,    // scanline count
          true,   // grayscale
      )

      filmPass.renderToScreen = true
      this.composer.addPass(filmPass)
      */

      //let envmaterial = new THREE.MeshBasicMaterial( {
      //  envMap: this.camera.renderTarget.texture
      //} );

      const ambientLight = new THREE.AmbientLight("#ffffff", 1.2)
      this.scene.add(ambientLight)

      // BUILD INTERFACE (passing GLTF Mesh object)
      const buildInterface = object => {

           const radius = 17

           let Grid = []
           let HorizontalLine = []
           let VerticalLine = []
           let Circle = []
           let Serpent = []
           let Carousel = []

           //ADD CUBE
           for (let i=0; i < S; i++) {

             // Clone GLTF Mesh ant it's Material
             let cube = object.scene.children[0].children[0].clone()
             let mat = cube.material
             cube.material = mat.clone()

             let angle = i / (S / 2) * Math.PI

             Items.push( cube )
             Items[i].name = i

             // CAROUSEL
             Carousel[i] = {
               positionx: (radius+1) * Math.sin(angle),
               positiony: 0,
               positionz: (radius+1) * Math.cos(angle),

               rotationx: 0,
               rotationy: angle + 1.5708,
               rotationz: 1.5708,

               offsetpositionx: 0,
               offsetpositiony: 0,
               offsetpositionz: 0,

               offsetrotationx: 0,
               offsetrotationy: - 1.5708,
               offsetrotationz: 0,

               camerapositionx: 28 * Math.sin(i / (S / 2) * Math.PI),
               camerapositiony: 0,
               camerapositionz: 28 * Math.cos(i / (S / 2) * Math.PI),

               selectedpositionx: (radius+2.8) * Math.sin(angle),
               selectedpositiony: 0,
               selectedpositionz: (radius+2.8) * Math.cos(angle),

               lookat: true
             }

             // GRID
             Grid[i] = {
               positionx: -20 + ((i%6) + (i%6)*7) ,
               positiony: -20 + (Math.floor(i/6) + Math.floor(i/6)*7),
               positionz: 0,

               rotationx: 0,
               rotationy: 0,
               rotationz: 0,

               offsetpositionx: 0,
               offsetpositiony: 0,
               offsetpositionz: 0,

               offsetrotationx: 0,
               offsetrotationy: 0,
               offsetrotationz: 0,

               camerapositionx: 6,
               camerapositiony: 6,
               camerapositionz: 19,

               selectedpositionx: -20 + ((i%6) + (i%6)*7),
               selectedpositiony: -20 + (Math.floor(i/6) + Math.floor(i/6)*7),
               selectedpositionz: 3,

               lookat: true
             }

             // HORIZONTAL LINE
             HorizontalLine[i] = {
               positionx: -40 + (i*2),
               positiony: 0,
               positionz: 0,

               rotationx: Math.PI/2,
               rotationy: Math.PI/2,
               rotationz: 0,

               offsetpositionx: 4,
               offsetpositiony: 0,
               offsetpositionz: 0,

               offsetrotationx: - 1.5708,
               offsetrotationy: - 1.5708,
               offsetrotationz: 0,

               camerapositionx: 6,
               camerapositiony: 6,
               camerapositionz: 19,

               selectedpositionx: -40 + (i*2),
               selectedpositiony: 0,
               selectedpositionz: 0,

               lookat: false
             }

             // VERTICAL LINE
             VerticalLine[i] = {
               positionx: 0,
               positiony: -S + (i*2),
               positionz: 0,

               rotationx: Math.PI/2,
               rotationy: 0,
               rotationz: 0,

               offsetpositionx: 0,
               offsetpositiony: 3,
               offsetpositionz: 0,

               offsetrotationx: - 1.5708,
               offsetrotationy: 0,
               offsetrotationz: 0,

               camerapositionx: -S + (i*2),
               camerapositiony: -S + (i*2),
               camerapositionz: -S + (i*2),

               selectedpositionx: 0,
               selectedpositiony: -S + (i*2),
               selectedpositionz: 0,

               lookat: false
             }

             // CIRCLE
             Circle[i] = {
               positionx: radius * Math.cos(angle),
               positiony: radius * Math.sin(angle),
               positionz: 0,

               rotationx: Math.PI/2,
               rotationy: angle,
               rotationz: 0,

               offsetpositionx: 0,
               offsetpositiony: 0,
               offsetpositionz: 0,

               offsetrotationx: 0,
               offsetrotationy: - 1.5708,
               offsetrotationz: 0,

               lookat: true
             }

             // SERPENT
             Serpent[i] = {
               positionx: radius * Math.sin(angle),
               positiony: -12+(i*1),
               positionz: radius * Math.cos(angle),

               rotationx: 0,
               rotationy: angle + 1.57,
               rotationz: 0,

               offsetpositionx: 0,
               offsetpositiony: 0,
               offsetpositionz: 0,

               offsetrotationx: 0,
               offsetrotationy: - 1.5708,
               offsetrotationz: 0,

               camerapositionx: 25 * Math.sin(i / (S / 2) * Math.PI),
               camerapositiony: -12+(i*1),
               camerapositionz: 25 * Math.cos(i / (S / 2) * Math.PI),

               selectedpositionx: (radius+1.4) * Math.sin(angle),
               selectedpositiony: -12+(i*1),
               selectedpositionz: (radius+1.4) * Math.cos(angle),

               lookat: true
             }

             Items[i].position.x = radius * Math.cos(angle)
             Items[i].position.y = radius * Math.sin(angle)
             Items[i].rotation.x = Math.PI/2
             Items[i].rotation.y = angle

             Items[i].ids = i;

             //TweenMax.to(Items[i].rotation, 1, {x: angle, z: angle, delay: i/20});
             //this.cameraTween = TweenLite.to(this.camera, 1, {x: 100, y: 100});

             this.scene.add(Items[i])
           }

           Positions.push( Carousel )
           Positions.push( Grid )
           Positions.push( HorizontalLine )
           Positions.push( VerticalLine )
           Positions.push( Circle )
           Positions.push( Serpent )
      }


      // GLTF loader
      const loader = new GLTFLoader()

      loader.load(
          carModel,
          ( gltf ) => {
              // called when the resource is loaded
              //this.scene.add( gltf.scene );
              buildInterface ( gltf )
              console.log('gltf', gltf);
              console.log('gltf.scene', gltf.scene);
              console.log('gltf.asset', gltf.asset);
          },
          ( xhr ) => {
              // called while loading is progressing
              console.log( `${( xhr.loaded / xhr.total * 100 )}% loaded` )
          },
          ( error ) => {
              // called when loading has errors
              console.error( 'An error happened', error )
          },
      )

      //this.controls = new OrbitControls(this.camera, this.renderer.domElement);

      this.start()

      window.addEventListener(
      'resize',
      () => {
        this.camera.aspect = window.innerWidth / window.innerHeight
        this.camera.updateProjectionMatrix()
        this.renderer.setSize(window.innerWidth, window.innerHeight)
      },
      false)

      document.addEventListener(
      'mousemove',  (event) => {
        event.preventDefault()
        mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1
				mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1
      }, false )

      this.mount.addEventListener(
      'click',  (event) => {

        // find intersections
        this.raycaster.setFromCamera( mouse, this.camera );
        var intersects = this.raycaster.intersectObjects( Items ); //, true );

        if ( intersects.length > 0 ) {
         for (let i=0; i < Items.length; i++) {
           Items[i].material.color.setHex( 0xFFFFFF );
         }
          INTERSECTED = intersects[ 0 ].object;
          SelectItem( INTERSECTED.ids )
        } else {
          INTERSECTED = null;
        }

      }, false )

      window.addEventListener(
      'keydown', (event) => {

          event = event || window.event;

          if (event.keyCode == '38') {
             // up arrow
             SelectItem( Number(CurrentSelection)+1 )
          }
          else if (event.keyCode == '40') {
             // down arrow
             SelectItem( Number(CurrentSelection)-1 )
          }
          else if (event.keyCode == '37') {
             // left arrow
             SelectItem( Number(CurrentSelection)-1 )
          }
          else if (event.keyCode == '39') {
             // right arrow
             SelectItem( Number(CurrentSelection)+1 )
          }

      }, false );
   }

   componentWillUnmount() {
      this.stop()
      this.mount.removeChild(this.renderer.domElement)
   }


   start = () => {
      if (!this.frameId) {
        this.frameId = requestAnimationFrame(this.animate)
      }
   }

   stop = () => {
     cancelAnimationFrame(this.frameId)
   }

   animate = () => {

     var rotSpeed = .002

     var x = this.camera.position.x,
         y = this.camera.position.y,
         z = this.camera.position.z;

     if (AnimatedCamera) {
         this.camera.position.x = x * Math.cos(rotSpeed) + z * Math.sin(rotSpeed)
         this.camera.position.y = x * Math.cos(rotSpeed) + z * Math.sin(rotSpeed)
         this.camera.position.z = z * Math.cos(rotSpeed) - x * Math.sin(rotSpeed)
     }

     this.camera.lookAt(new THREE.Vector3(0,0,0))

     // find intersections
     this.raycaster.setFromCamera( mouse, this.camera );
     var intersects = this.raycaster.intersectObjects( Items ); //, true );

     if ( intersects.length > 0 ) {

      for (let i=0; i < Items.length; i++) {
        Items[i].material.color.setHex( 0xFFFFFF );
      }
       INTERSECTED = intersects[ 0 ].object;
       INTERSECTED.material.color.setHex( 0xA5A5A4 );
       document.querySelector('.cursor-text').innerText = 'ITEM NO. ' + INTERSECTED.ids
     } else {

       if ( INTERSECTED ) {
         INTERSECTED.material.color.setHex( 0xFFFFFF );
       }
       INTERSECTED = null;

       document.querySelector('.cursor-text').innerText = ''
     }


     //this.camera.rotation.x += 0.01
     //this.camera.rotation.y += 0.01

     //for (let i=0; i < S; i++){
       //Items[i].rotation.x = Math.cos(0.05)
       //Items[i].rotation.y = Math.sin(0.05)
     //}

     this.renderScene()
     this.frameId = window.requestAnimationFrame(this.animate)
   }

   renderScene = () => {
     //this.renderer.render(this.scene, this.camera)
  	 this.composer.render(this.clock.getDelta())
   }

   render(){
     return(
       <>
         <ViewNavigation
           fn={ChangeView}
         />
         <Headline/>
         <div
           style={{
             width: '100%',
             height: '100vh',
             background: 'url(/static/media/grain.72464563.png) no-repeat',
             backgroundSize: 'cover'
           }}
           ref={(mount) => { this.mount = mount }}
         />
       </>

     )
   }
}
export default ThreeScene
